import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../scss/global.scss";
import Navbar from "../header/Navbar";
import Footer from "../Footer";

export default function Layout({ pageTitle, children }) {
  useEffect(() => {
    window.hfAccountId = "34181fe4-e714-4019-a6bb-513fdb1d0fbd";
    window.hfDomain = "https://api.herefish.com";
    const hfScript = document.createElement("script");
    hfScript.type = "text/javascript";
    hfScript.async = true;
    hfScript.src = `${window.hfDomain}/scripts/hf.js`;
    document.head.appendChild(hfScript);
  }, []);

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {pageTitle
            ? `${pageTitle} | Performance Energy | Specialists in mobilizing experienced personnel`
            : "Performance Energy | Specialists in mobilizing experienced personnel"}
        </title>
        <link rel="canonical" href="https://www.performance-energy.com" />
        <script>
          {`
            window.hfAccountId = "34181fe4-e714-4019-a6bb-513fdb1d0fbd";
            window.hfDomain = "https://api.herefish.com";
            (function() {
                var hf = document.createElement('script'); 
                hf.type = 'text/javascript'; 
                hf.async = true;
                hf.src = window.hfDomain + '/scripts/hf.js';
                var s = document.getElementsByTagName('script')[0]; 
                s.parentNode.insertBefore(hf, s);
            })();
        `}
        </script>
      </Helmet>

      {/*<Router>*/}
      <Navbar />
      {/*</Router>*/}
      <main>{children}</main>

      <Footer />
    </div>
  );
}
